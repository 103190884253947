export default [
  {
    header: 'CRM',
    action: 'employee',
    resource: 'read',
  },
  {
    title: 'Utenti',
    route: 'crm-utenti-index',
    icon: 'UserIcon',
    action: 'employee',
    resource: 'manage',
  },
  {
    title: 'Leads',
    route: 'crm-leads-index',
    icon: 'UsersIcon',
    action: 'employee',
    resource: 'read',
  },
  {
    title: 'Clienti',
    route: 'crm-customers-index',
    icon: 'BriefcaseIcon',
    action: 'employee',
    resource: 'read',
  },
  {
    title: 'Agenti',
    route: 'crm-agenti-index',
    icon: 'DatabaseIcon',
    action: 'employee',
    resource: 'read',
  },
  {
    title: 'Licenze',
    route: 'crm-licenze-index',
    icon: 'AwardIcon',
    action: 'employee',
    resource: 'read',
  },
  {
    title: 'Licenze Old',
    route: 'crm-licenze-index-old',
    icon: 'AwardIcon',
    action: 'employee',
    resource: 'read',
  },
  {
    title: 'Ricerche',
    route: 'crm-ricerche-index',
    icon: 'SearchIcon',
    action: 'employee',
    resource: 'read',
  },
  {
    title: 'Pagamenti',
    route: 'crm-pagamenti-index',
    icon: 'CheckCircleIcon',
    action: 'employee',
    resource: 'read',
  },
  {
    title: 'Fatture',
    route: 'crm-fatture-index',
    icon: 'FileTextIcon',
    action: 'employee',
    resource: 'read',
  },
]
